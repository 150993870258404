/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Button from 'elements/Button';
import logo from '../assets/images/icon2.png';

export default function BrandIcon() {
  return (
    <Button
      className=""
      type="link"
      href="/"
    >
      <div className="text-theme-blue text-4xl" style={{ display: 'flex' }}>
        <img src={logo} alt="Logo" className="logo w-[50px]" />
        ER
        <span className="text-theme-purple">apid</span>
      </div>
    </Button>
  );
}
