/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Fade from 'react-reveal/Fade';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default function OurClients({ data }) {
  return (
    <Fade bottom>
      <section className="container mx-auto">
        <h1 className="text-5xl text-theme-blue text-center font-bold">Our Clients</h1>

        <p className="font-light text-lg text-gray-400 text-center mb-3 sm:mb-3 xl:mb-4">
          Our Happy cutomers
        </p>

        <div className="grid grid-cols-2 sm:grid-cols-4 sm:gap-2 xl:gap-8 justify-items-center">
          {
            data.map((item, index) => (
              <Fade bottom delay={500 * index}>
                <div className="group rounded-2xl shadow-xl w-auto w-11/12 m-3 transform transition duration-500 hover:scale-110 portofolio-card">
                  <div className="relative">
                    <img src={item.imageUrl} alt="Portfolio" className="rounded-t-2xl z-0 companyBox" />

                  </div>
                </div>
              </Fade>
            ))
          }
        </div>

      </section>
    </Fade>
  );
}
